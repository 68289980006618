import React, { useEffect } from 'react';
import styled from 'styled-components';

const FormPreview = ({ fields, styles }: any) => {
  let customCssStyleElement: HTMLStyleElement | null = null;
  const injectCustomCss = (customCss: string) => {
    if (customCssStyleElement) {
      customCssStyleElement.remove();
    }
    customCssStyleElement = document.createElement('style');
    customCssStyleElement.innerHTML = customCss;
    document.head.appendChild(customCssStyleElement);
  };
  useEffect(() => {
    if (styles?.customCss) {
      injectCustomCss(styles.customCss || '');
    } else if (customCssStyleElement) {
      customCssStyleElement.remove();
      customCssStyleElement = null;
    }
  }, [styles?.customCss]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <FormContainer
      formWidth={styles?.formWidth}
      unitForForm={styles?.formWidthOptions}
      formBackgroundColor={styles?.formBackgroundColor}
      formBorderType={styles?.formBorderType}
      formBorderColor={styles?.formBorderColor}
      formBorderWidth={styles?.formBorderWidth}
      formBorderRadius={styles?.formBorderRadius}
      formPadding={styles?.formPadding}
      formMargin={styles?.formMargin}
      formBackgroundImageUrl={styles?.formBackgroundImageUrl}
      formBackgroundImageFitting={styles?.formBackgroundImageFitting}
      fieldGap={styles?.fieldGap}
      backgroundRepeat={styles?.backgroundRepeat}
      backgroundPosition={styles?.backgroundPosition}

    >
      {fields.map((fieldGroup: any, rowIndex: number) =>
        fieldGroup?.map((field: any) => (
          <FieldContainer key={field.id} fieldWidth={field.width}>
            {styles?.show_label && (
              <div style={{ marginBottom: styles?.labelMarginBottom }}>
                <Label
                  fieldLabelColor={styles?.fieldLabelColor}
                  fieldLabelCase={styles?.fieldLabelCase}
                >
                  {field?.label}
                </Label>
              </div>
            )}
            {['string', 'email', 'landline', 'phone'].includes(field.type) && !field.hide && (
              <InputField
                className={field.className}
                id={field.idName}
                fieldWidth={styles?.fieldWidth}
                fieldHeight={styles?.fieldHeight}
                fieldBorderRadius={styles?.fieldBorderRadius}
                fieldBorderColor={styles?.fieldBorderColor}
                fieldPadding={styles?.fieldPadding}
                fieldMargin={styles?.fieldMargin}
                fieldFontSize={styles?.fieldFontSize}
                fieldPlaceholderColor={styles?.fieldPlaceholderColor}
                placeholder={field?.placeholder}
                fieldTextColor={styles?.fieldTextColor}
              />
            )}
            {
              field?.type === "datetime" && !field.hide && (
                <InputField
                  className={field.className}
                  id={field.idName}
                  fieldWidth={styles?.fieldWidth}
                  fieldHeight={styles?.fieldHeight}
                  fieldBorderRadius={styles?.fieldBorderRadius}
                  fieldBorderColor={styles?.fieldBorderColor}
                  fieldPadding={styles?.fieldPadding}
                  fieldMargin={styles?.fieldMargin}
                  fieldFontSize={styles?.fieldFontSize}
                  fieldPlaceholderColor={styles?.fieldPlaceholderColor}
                  placeholder={""}
                  fieldTextColor={styles?.fieldTextColor}
                  type="date"
                />
              )
            }
            {field?.type === 'textarea' && !field.hide && (
              <TextareaField
                className={field.className}
                id={field.idName}
                fieldWidth={styles?.fieldWidth}
                fieldHeight={styles?.fieldHeight}
                fieldBorderRadius={styles?.fieldBorderRadius}
                fieldBorderColor={styles?.fieldBorderColor}
                fieldPadding={styles?.fieldPadding}
                fieldMargin={styles?.fieldMargin}
                fieldFontSize={styles?.fieldFontSize}
                fieldPlaceholderColor={styles?.fieldPlaceholderColor}
                fieldTextColor={styles?.fieldTextColor}
                placeholder={field?.placeholder}
                rows={field?.rows || 10}
                cols={field?.cols || 50}
              />
            )}
            {['dropdown', 'radio'].includes(field.type) && !field.hide && (
              <SelectField
                className={field.className}
                id={field.idName}
                fieldWidth={styles?.fieldWidth}
                fieldHeight={styles?.fieldHeight}
                fieldBorderRadius={styles?.fieldBorderRadius}
                fieldBorderColor={styles?.fieldBorderColor}
                fieldPadding={styles?.fieldPadding}
                fieldMargin={styles?.fieldMargin}
                fieldFontSize={styles?.fieldFontSize}
                fieldPlaceholderColor={styles?.fieldPlaceholderColor}
                fieldTextColor={styles?.fieldTextColor}
                placeholder={field?.placeholder || "Select an option"}
              >
                <option value="" disabled selected>Select your option</option>
              </SelectField>
            )}
          </FieldContainer>
        ))
      )}
      <FullWidthDiv>
        <DeclarationContainer styles={styles}>
          {
            styles?.declaration && (
              <React.Fragment>
                <input type="checkbox" defaultChecked={styles?.declaration_value} />
                <p>{styles?.declaration}</p>
              </React.Fragment>
            )
          }
        </DeclarationContainer>
        <ButtonContainer buttonPosition={styles?.button_style?.button_position}>
          <Button
            buttonBackgroundColor={styles?.button_background_color}
            buttonTextColor={styles?.button_text_color}
            buttonfontSize={styles?.buttonfontSize}
            buttonRadius={styles?.button_style?.button_radius}
            buttonPadding={styles?.buttonPadding}
            buttonMargin={styles?.buttonMargin}
            coverWholeWidth={styles?.button_style?.cover_whole_width}
            buttonBackgroundHoverColor={styles?.button_background_hover_color}
            buttonTextHoverColor={styles?.button_text_hover_color}
            buttonBorder={styles?.buttonBorder}
            buttonBorderColor={styles?.buttonBorderColor}
            buttonWeight={styles?.buttonWeight}
          >
            {styles?.button_name}
          </Button>
        </ButtonContainer>
      </FullWidthDiv>
    </FormContainer>
  );
};

export default FormPreview;

// Styled Components
const FormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(12, 1fr); 
  width: ${(props: any) => props.formWidth + props.unitForForm?.name};
  height: 100%;
  background-color: ${(props: any) => props.formBackgroundColor};
  border: ${(props: any) => `${props.formBorderWidth} ${props.formBorderType} ${props.formBorderColor}`};
  border-radius: ${(props: any) => props.formBorderRadius};
  padding: ${(props: any) => props.formPadding};
  margin: ${(props: any) => props.formMargin};
  background-image: url(${(props: any) => props.formBackgroundImageUrl});
  background-size: ${(props: any) => props.formBackgroundImageFitting};
  gap: ${(props: any) => props?.fieldGap || '16px'};
  background-repeat: ${(props: any) => props?.backgroundRepeat || 'no-repeat'};
  background-position: ${(props: any) => props?.backgroundPosition || 'center'};
  background-attachment: fixed;
`;

const FieldContainer = styled.div`
  grid-column: span ${(props: any) => Math.round((props.fieldWidth / 100) * 12)};
  margin-bottom: ${(props: any) => props.styles?.fieldGap || '8px'}; 
`;

const InputField = styled.input`
  width: ${(props: any) => props.fieldWidth};
  height: ${(props: any) => props.fieldHeight};
  border-radius: ${(props: any) => props.fieldBorderRadius};
  border: 1px solid ${(props: any) => props.fieldBorderColor};
  padding: ${(props: any) => props.fieldPadding};
  margin: ${(props: any) => props.fieldMargin};
  font-size: ${(props: any) => props.fieldFontSize};
  color: ${(props: any) => props.fieldTextColor};
  &::placeholder {
    color: ${(props: any) => props.fieldPlaceholderColor};
  }
`;

const TextareaField = styled.textarea`
  width: ${(props: any) => props.fieldWidth};
  height: ${(props: any) => props.fieldHeight};
  border-radius: ${(props: any) => props.fieldBorderRadius};
  border: 1px solid ${(props: any) => props.fieldBorderColor};
  padding: ${(props: any) => props.fieldPadding};
  margin: ${(props: any) => props.fieldMargin};
  font-size: ${(props: any) => props.fieldFontSize};
  color: ${(props: any) => props.fieldTextColor};
  resize: none;
  &::placeholder {
    color: ${(props: any) => props.fieldPlaceholderColor};
  }
  outline: none;
`;

const SelectField = styled.select`
  width: ${(props: any) => props.fieldWidth};
  height: ${(props: any) => props.fieldHeight};
  border-radius: ${(props: any) => props.fieldBorderRadius};
  border: 1px solid ${(props: any) => props.fieldBorderColor};
  padding: ${(props: any) => props.fieldPadding};
  margin: ${(props: any) => props.fieldMargin};
  font-size: ${(props: any) => props.fieldFontSize};
  color: ${(props: any) => props.fieldPlaceholderColor};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props: any) => {
    switch (props.buttonPosition) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'center':
        return 'center';
      default:
        return 'flex-start';
    }
  }};
  align-items: center;
  width: 100%; // Ensure the container takes the full width
`;

const FullWidthDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column: 1 / -1;
`;

const Button = styled.button`
  background-color: ${(props: any) => props.buttonBackgroundColor};
  color: ${(props: any) => props.buttonTextColor};
  border-radius: ${(props: any) => props.buttonRadius};
  padding: ${(props: any) => props.buttonPadding};
  margin: ${(props: any) => props.buttonMargin};
  width: ${(props: any) => (props.coverWholeWidth ? '100%' : 'auto')};
  border: ${(props: any) => `${props.buttonBorder} solid ${props?.buttonBorderColor}`};
  font-size: ${(props: any) => props.buttonfontSize};
  cursor: pointer;
  font-weight: ${(props: any) => props.buttonWeight};
  &:hover {
    background-color: ${(props: any) => props.buttonBackgroundHoverColor};
    color: ${(props: any) => props.buttonTextHoverColor};
  }
`;

const Label = styled.label`
  color: ${(props: any) => props.fieldLabelColor};
  text-transform: ${(props: any) => (props.fieldLabelCase?.value === 'yes' ? 'uppercase' : 'none')};
  margin-bottom: ${(props: any) => props.labelMarginBottom || '6px'};
`;

const DeclarationContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props: any) => props.styles?.fieldGap || '8px'};
  display: flex;
  align-items: center;

  input {
    margin-right: 8px;
  }

  p {
    margin: 0;
    color: ${(props: any) => props.styles?.declarationColor};
    font-size: ${(props: any) => props.styles?.declarationFontSize};
  }
`;