import moment from "moment";
import { Box, Grid } from "@mui/material";
import { ProtectedAppUiElement } from "../../../../AppComponents/ProtectedComponents";
import { MyBotCard } from "../../../../Customization/CommonElements";
import { DefaultTemplateActionButton, TemplateBody } from "./Styles";
import { useNavigate } from "react-router-dom";
interface Props {
    children?: React.ReactNode;
    loading?: any;
    list: any
}

const DefaultTemplateCard = (props: Props) => {
    const navigate = useNavigate();
    const createTemplate = (data:any) => {
        navigate("/broadcast/whatsapp/create-template",{'state':{
            data : data,
            action:"default_template"
        }})
    }   
    return (
        <Box sx={{
            height: "715px",
            overflow: "scroll"
        }}>
            {props?.list && props?.list.length ? (
                <Box
                    sx={{
                        flexGrow: 1,
                        padding: "1rem 2rem",
                        height: "100%",
                    }}
                >
                    <Grid
                        container
                        spacing={{ xs: 2, xl: 4 }}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                    >
                        {props?.list &&
                            props?.list.length > 0 &&
                            props?.list.map((bot: any, index: number) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                                        <MyBotCard elevation={0} key={index}>
                                            <div className="botTitleBox" style={{ padding: "22px", backgroundColor: "rgb(122 122 122)", color: "white", fontSize: "15px" }}>
                                                {bot?.name}
                                            </div>
                                            <TemplateBody >
                                                {bot?.components?.map((component: any) => {
                                                    return (
                                                        <>
                                                            {component?.type === "HEADER" ? <p title={bot?.name} className="botTitle">
                                                                {bot?.components[0]?.format === "TEXT" ? bot?.components[0]?.text : <img style={{ marginLeft: "100px" }} height={100} src={component?.example?.header_handle[0]} />}
                                                            </p> : null}
                                                            {component?.type === "BODY" ? <p style={{ marginTop: "10px" }} className="botSummary">{component?.text}</p> : null}
                                                            {component?.type === "BUTTONS" ? component?.buttons?.map((res: any) => {
                                                                return (
                                                                    <p style={{
                                                                        marginTop: "10px", borderRadius: "15px",
                                                                        padding: "7px",
                                                                        backgroundColor: "antiquewhite", border: "2px solid lightgray"
                                                                    }} className="botSummary">{res?.text}</p>
                                                                )
                                                            })

                                                                : null}
                                                        </>
                                                    )
                                                })}
                                            </TemplateBody>

                                            <ProtectedAppUiElement moduleId="manage_bot">
                                                <DefaultTemplateActionButton
                                                    className="botActionBox"
                                                >
                                                    <Box
                                                    className="actions"
                                                    onClick={() => createTemplate(bot)}
                                                    >
                                                        {/* <ManageBot /> */}
                                                        <p style={{ marginBottom: "10px" }}>Use This Template</p>
                                                    </Box>

                                                </DefaultTemplateActionButton>
                                            </ProtectedAppUiElement>
                                        </MyBotCard>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default DefaultTemplateCard;
