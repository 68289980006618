import { Box } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../Store";
import { broadcastActions } from "../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { removebroadcastDashboard } from "../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import AppSkeletonLoader from "../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import NoRecordImage from '../../../../../assets/images/norecordsanalytics.svg';
import BroadcastTableInfo from "./components/BroadcastTableInfo";
import BroadcastTemplateTableInfo from "./components/BroadcastTemplateTableInfo";
import Doughnout from "./components/Doughnout";
import TopButtonsClick from "./components/TopButtonsClick";
import { SummaryWrapper, WrapRecipients, WrapTableReci } from "./Styles";
import { whatsAppBroadcastOverviewLayout } from "./utils";
import { Statistics } from "./components/Statistics";

interface Props {
    domElements?: any[];
    className?: string;
    rowHeight?: number;
    onLayoutChange?: (layout: any, layouts: any) => void;
    cols?: any;
    breakpoints?: any;
    containerPadding?: number[];
    preventCollision?: boolean;
    margin?: any;
}

const ResponsiveGridLayout: React.ComponentClass<
    RGL.ResponsiveProps & RGL.WidthProviderProps,
    any
> = WidthProvider(Responsive);

const WhatappBroadcastOverview: FunctionComponent<Props> = (props) => {
    const { showAlert } = useNotification();
    const [statsData, setStatsData] = useState<any>([]);
    const [rates, setRates] = useState<any>({})
    const [broadcastDetails, setBroadcastDetails] = useState<any>([]);
    const broadcast_id = useParams();
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const getStatsData = () => {
        setLoading(true);
        dispatch(broadcastActions.getWhatsappBroadcastOverview(broadcast_id?.broadcast_id))
            .then((res: any) => {
                setStatsData(res?.payload?.data?.statistics);
                setRates(res?.payload?.data?.rates_stats)
                setBroadcastDetails(res?.payload?.data?.broadcast_stats);
                setLoading(false);
            })
            .catch((error: any) => {
                showAlert(
                    error?.response?.statusText || "Unable to fetch data",
                    "error"
                );
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getStatsData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            dispatch(removebroadcastDashboard());
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hasRecipientsTableInfoData = (
        broadcastDetails &&
        (broadcastDetails?.template_name ||
            broadcastDetails?.template_type ||
            broadcastDetails?.sender_number)
    ) ? true : false;
    const hasWhatsAppRecipientsTableData = (
        broadcastDetails &&
        (broadcastDetails?.subject || broadcastDetails?.from_email)
    ) ? true : false;

    return (
        <SummaryWrapper>
            {!loading &&
                !hasRecipientsTableInfoData &&
                !hasWhatsAppRecipientsTableData &&
                (statsData?.recipients ?? 0) === 0 &&
                (statsData?.total_cost ?? 0) === 0 &&
                (statsData?.sent ?? 0) === 0 &&
                (statsData?.delivered ?? 0) === 0 &&
                (statsData?.seen ?? 0) === 0 &&
                (statsData?.replied ?? 0) === 0 ?

                (
                    <Box m={"1rem"} height={"100%"}>
                        <NoRecordsFound
                            overflow={true}
                            headerTextSize={"19px"}
                            height={"calc(100vh - 199px)"}
                        />
                    </Box>
                ) : (
                    <div className="parent_div2">

                        <ResponsiveGridLayout
                            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                            cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                            layouts={whatsAppBroadcastOverviewLayout}
                            isDraggable={false}
                            isResizable={false}
                        >

                            <div key={"1"}>
                                <div className="box-wrapper">
                                    {loading ? (
                                        <AppSkeletonLoader />
                                    ) : (
                                        <Box height={"inherit"}>

                                            <WrapRecipients>
                                                {
                                                    (statsData?.sent ?? 0) === 0 &&
                                                        (statsData?.total_messages ?? 0) === 0 ? (
                                                        <NoRecordsFound
                                                            imageSrc={NoRecordImage}
                                                            imageWidth={"180px"}
                                                            headerTextSize={"19px"}
                                                            bgColor="transparent"
                                                            headerTextColor="#fff"
                                                            subTextColor="#fff"
                                                        />
                                                    ) : (
                                                        <div className="box-wrapper">
                                                            <Box display={"flex"} alignItems={"center"}>
                                                                <p className="a_div_text">
                                                                    Recipients: {statsData?.total_messages || 0}
                                                                </p>
                                                                <AppToolTip
                                                                    tooltipProps={{
                                                                        title:
                                                                            "Total individuals for WhatsApp message delivery.",
                                                                        placement: "top",
                                                                    }}
                                                                    iconButtonProps={{ disableRipple: true }}
                                                                    IconComponent={
                                                                        <AppMaterialIcons
                                                                            iconName="HelpOutline"
                                                                            style={{ color: "#fff", fontSize: "16px" }}
                                                                        />
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box display={"flex"} flexDirection={"column"}>
                                                                <Box className="credits_btn" sx={{ display: 'inline-block', width: 'fit-content' }}>
                                                                    <p>Cost: Rs {broadcastDetails?.billable_amount} </p>
                                                                </Box>
                                                                <Box className="credits_btn" sx={{ display: 'inline-block', width: 'fit-content' }}>
                                                                    <p>Refunded Amount: Rs {broadcastDetails?.refunded_amount} </p>
                                                                </Box>
                                                            </Box>
                                                        </div>
                                                    )
                                                }
                                            </WrapRecipients>
                                        </Box>
                                    )}
                                </div>
                            </div>

                            {/* Only Here BrodcastDetails Values go */}
                            <div key={"2"}>
                                <div className="box-wrapper">
                                    {loading ? (
                                        <AppSkeletonLoader />
                                    ) : (
                                        <WrapTableReci>
                                            <BroadcastTemplateTableInfo broadcastDetails={broadcastDetails} />
                                        </WrapTableReci>
                                    )}
                                </div>
                            </div>

                            {/* Only Here BrodcastDetails Values go */}
                            <div key={"3"}>
                                <div className="box-wrapper">
                                    {loading ? (
                                        <AppSkeletonLoader />
                                    ) : (
                                        <WrapTableReci>
                                            <BroadcastTableInfo broadcastDetails={broadcastDetails} />
                                        </WrapTableReci>
                                    )}
                                </div>
                            </div>

                            <div key={"4"}>
                                <Statistics
                                    statsData={statsData}
                                    broadcastDetails={broadcastDetails}
                                    loading={loading}
                                />
                            </div>

                            <div key={"5"}>
                                <Box className="box-wrap">

                                    <Doughnout
                                        broadcastStats={rates?.delivery_rate || 0}
                                        loading={loading}
                                        heading={"Deliverability Rate"}
                                        color={"#45A6FF"}
                                        tooltiptext={
                                            "Percentage of successfully sent WhatsApp messages from total attempts."
                                        }
                                        chartText={`${statsData?.delivered || 0} out of
                                    ${statsData?.sent || 0} emails were delivered
                                    without bouncing`}
                                        downloadBtnPosition={-45}
                                    />
                                </Box>
                            </div>

                            <div key={"6"}>
                                <Box className="box-wrap">

                                    <Doughnout
                                        broadcastStats={rates?.seen_rate || 0}
                                        loading={loading}
                                        totalText={`Total: ${statsData?.seen || 0}`}
                                        heading={"Seen Rate"}
                                        color={"#B57AFF"}
                                        tooltiptext={
                                            "The percentage of delivered whatsApp that were opened by recipients, indicating the level of engagement or interest in the content."
                                        }
                                        chartText={`Your whatsApp to ${statsData?.sent || 0} people
                                      was opened a total of ${statsData?.replied || 0}
                                      times by ${statsData?.sent || 0} subscribers.`}
                                        downloadBtnPosition={-43}
                                    />
                                </Box>
                            </div>


                            <div key={"7"}>
                                <Box className="box-wrap">

                                    <Doughnout
                                        broadcastStats={rates?.reply_rate || 0}
                                        totalText={`Total: ${statsData?.replied || 0}`}
                                        loading={loading}
                                        heading={"Replied Rate"}
                                        color={"#FFC164"}
                                        tooltiptext={
                                            "The proportion of delivered whatsApp that recipients interacted with by clicking on embedded links or calls-to-action, reflecting engagement with the whatsapp content."
                                        }
                                        chartText={`Your whatsapp to ${statsData?.recipients || 0} people
                                      was clicked a total of ${statsData?.clicked || 0}
                                      times by ${statsData?.sent || 0} subscribers.`}
                                        downloadBtnPosition={-43}
                                    />


                                </Box>
                            </div>

                            <div key={"8"}>
                                <Box className="box-wrap">
                                    <Doughnout
                                        totalText={`Total: ${statsData?.failure_rate || 0}`}
                                        broadcastStats={rates?.failed}
                                        loading={loading}
                                        heading={"Failure Rate"}
                                        color={"#FFC164"}
                                        tooltiptext={
                                            "The proportion of delivered whatsApp that recipients interacted with by clicking on embedded links or calls-to-action, reflecting engagement with the whatsapp content."
                                        }
                                        chartText={`Your whatspp to ${statsData?.recipients || 0} people
                                      was clicked a total of ${statsData?.clicked || 0}
                                      times by ${statsData?.sent || 0} subscribers.`}
                                        downloadBtnPosition={-43}
                                    />
                                </Box>
                            </div>
                        </ResponsiveGridLayout>
                    </div>
                )
            }
        </SummaryWrapper>

    );
};

WhatappBroadcastOverview.defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: (layout: any, layouts: any) => { },
    cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    containerPadding: [16, 16],
    margin: [16, 16],
    preventCollision: true,
};

export default WhatappBroadcastOverview;

export const DateBox = styled.div`
  color: blue;
`;
